<template>
  <div class="flex-box flex-box vertical">
    <level-group :type="type" class="level-wrapper" />
    <div class="flex-grow flex-box vertical content">
      <div class="data-title">
        <div class="rec-title-text">共{{ pagination.total }}条记录</div>
        <a-form-model class="query-form" layout="inline">
          <a-form-model-item label="预警时间">
            <a-range-picker
              v-model="form.alarm_time"
              :show-time="{ format: 'HH:mm' }"
              :placeholder="['开始时间', '结束时间']"
              format="YYYY-MM-DD HH:mm"
              value-format="YYYY-MM-DD HH:mm"
              @change="updateList"
              class="range-picker"
            />
          </a-form-model-item>
          <a-form-model-item label="处理状态">
            <a-select
              v-model="form.status"
              :options="statusOptions"
              placeholder="选择处理状态"
              allowClear
              style="width: 200px"
              @change="updateList"
            ></a-select>
          </a-form-model-item>
        </a-form-model>
        <a-button @click="updateList">查询</a-button>
      </div>
      <div class="data-area">
        <a-table
          ref="dataTable"
          :columns="columns"
          row-key="id"
          :data-source="list"
          :loading="loading"
          :pagination="pagination"
          :scroll="{ y: h }"
          @change="handleTableChange"
        >
          <template v-slot:action="text, record">
            <div class="row-btn">
              <a class="txt-btn" @click.stop="showDetail(record)">查看</a>
            </div>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty, getKeyTitle } from "../../common/js/tool";
import { statusOptions, levelOptions, warningOptions } from "../../common/constant/safe";

export default {
  name: "SmokeRecord",
  data() {
    return {
      type: 1, // 烟雾
      h: 500,
      list: [],
      form: {},
      loading: false,
      columns: [
        { title: "预警时间", dataIndex: "alarm_time" },
        {
          title: "预警类型",
          dataIndex: "type",
          customRender: (text) => <span>{getKeyTitle(warningOptions, text, "value", "label")}</span>,
        },
        {
          title: "预警级别",
          dataIndex: "alarm_level",
          customRender: (text) => <span>{getKeyTitle(levelOptions, text, "value", "label")}</span>,
        },
        { title: "报警设备", dataIndex: "device_name" },
        { title: "设备类型", dataIndex: "device_type" },
        { title: "设备位置", dataIndex: "device_location" },
        {
          title: "处理状态",
          dataIndex: "status",
          customRender: (text) => <span>{getKeyTitle(statusOptions, text, "value", "label")}</span>,
        },
        { title: "操作", key: "action", fixed: "right", width: 120, scopedSlots: { customRender: "action" } },
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
      },
      searchKeyType: {
        alarm_time: function(form, key) {
          let value = form[key];
          let res = "";
          if (value) {
            res = `&filter[${key}][gte]=${value[0]}&filter[${key}][lte]=${value[1]}`;
          }
          return res;
        },
      },
      statusOptions,
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    this.setHeight();
    window.addEventListener("resize", this.setHeight, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setHeight, false);
  },
  methods: {
    showDetail(record) {
      this.$router.push({ name: "SafeDetail", params: { id: record.id } });
    },
    handleTableChange(pagination) {
      let pager = { ...this.pagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.pagination = pager;
      this.getList();
    },
    updateList() {
      this.pagination.current = 1;
      this.getList();
    },
    getList() {
      this.loading = true;
      let url = `/admin/security-work-order?sort=-id&filter[type]=${this.type}`;
      let { current, pageSize } = this.pagination;
      url += `&page=${current}&pageSize=${pageSize}`;
      url += this.getQuery();
      this.$axios({ url, noTempleFilter: false })
        .then((res) => {
          this.loading = false;
          let list = res.data;
          this.list = list;
          if (res.page) {
            this.$set(this.pagination, "total", res.page.totalCount);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setHeight() {
      let dataTale = this.$refs.dataTable;
      if (dataTale) {
        let area = this.$el.querySelector(".data-area");
        this.h = area.clientHeight - 54 - 64;
      }
    },
    getQuery(ex) {
      let query = "";
      let form = this.form;
      let searchKeyType = this.searchKeyType;
      for (let key in form) {
        if (!isEmpty(form[key])) {
          //模糊搜索
          let type = searchKeyType[key] || 0;
          if (typeof type == "function") {
            query += type(form, key);
          } else if (type == 1) {
            query += `&filter[${key}][like]=${form[key]}`;
          } else if (type == 2) {
            query += `&${key}=${form[key]}`;
          } else {
            if (ex) {
              query += `&${key}=${form[key]}`;
            } else {
              query += `&filter[${key}]=${form[key]}`;
            }
          }
        }
      }
      return ex ? query.replace("&", "?") : query;
    },
  },
};
</script>

<style lang="less" scoped>
.ant-input-affix-wrapper {
  width: 180px;
}
.range-picker {
  text-align: left;
}
.level-wrapper {
  margin: 16px 16px 0;
}
</style>
